
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const base = namespace("base");

@Component({
  model: {
    prop: "methodList",
    event: "change"
  }
})
export default class NoticeChannel extends Vue {
  @base.Action getDictionaryList;
  @base.Action queryStoreMessageBalance;
  @base.State msmBalance;
  @Prop({})
  methodList: any;
  @Prop({ default: () => [] })
  defaultChannel: any;
  @Prop({ default: false })
  onlyWeixin: any;
  created() {
    this.init();
  }
  noticeMethodList = [];
  // msmBalance: number | string = "";
  init() {
    this.getDictionaryList("notify_method").then(data => {
      this.noticeMethodList = data.map(item => {
        if (this.defaultChannel && this.defaultChannel.includes(item.value)) {
          item.checked = true;
        } else {
          item.checked = false;
        }
        return item;
      });
      this.handleMethodChange();
    });
    this.queryStoreMessageBalance().then(data => {
      // this.msmBalance = data.data.smsBalance;
    });
  }
  handleMethodChange() {
    let checkItem = this.noticeMethodList.filter(item => item.checked).map(item => item.value);
    this.$emit("change", checkItem);
  }

  @Watch("defaultChannel")
  watchDefaultChannel(val) {
    if (!this.noticeMethodList.length) {
      return;
    }
    const checkItem = [];
    this.noticeMethodList.forEach(method => {
      if (val.includes(method.value)) {
        method.checked = true;
        checkItem.push(method.value);
      } else {
        method.checked = false;
      }
    });
    this.$emit("change", checkItem);
  }
  @Watch("methodList")
  watchMethodList(val) {
    this.noticeMethodList.forEach(method => {
      if (val.includes(method.value)) {
        method.checked = true;
      } else {
        method.checked = false;
      }
    });
  }
}

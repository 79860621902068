
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { verifyMoney } from "@/utils/verify";
import noticeChannel from "@/views/marketing/components/notice-channel.vue";
import t from "@common/src/i18n/t";
const base = namespace("base");
const marketing = namespace("marketing");
@Component({
  components: {
    noticeChannel
  }
})
export default class ReturnRuleSet extends Vue {
  @base.Action getDictionaryList;
  @marketing.Action saveRevisitRule;
  @marketing.Action getRevisitRule;
  //计算属性
  get breadData() {
    return [];
  }
  @Watch("returnForm.revisitCountType")
  revisitCountTypeChange() {
    if (this.returnForm.revisitCountType === "1") {
      this.returnForm.numberNoLimit = undefined;
    } else {
      this.returnForm.ritioNoLimit = undefined;
    }
  }
  @Watch("returnForm.moneyNoLimit")
  moneyNoLimitTypeChange() {
    if (this.returnForm.moneyNoLimit === "1") {
      this.returnForm.amountLimit = undefined;
    }
  }
  selectchannel = [];
  returnForm = {
    moneyNoLimit: "1",
    revisitCountType: "1",
    numberNoLimit: undefined,
    ritioNoLimit: undefined,
    amountLimit: undefined,
    status: "0",
    revisitType: "2", // 1-手动 2-系统
    automaticPushStrategy: "1",
    notifyMethods: [],
    pushWx: false,
    pushSms: false
  };
  get showOrderFilter() {
    return this.returnForm.revisitType === "1" || this.returnForm.automaticPushStrategy !== "2";
  }
  multipleValueVerity(value, callback) {
    if (!this.returnForm.amountLimit && value === "2") {
      callback(new Error(t("v210831.please-enter-the-amount-astrict")));
    } else if (this.returnForm.amountLimit) {
      // priceRulesEl(this.returnForm.amountLimit, callback);
      if (verifyMoney(this.returnForm.amountLimit)) {
        callback();
      } else {
        callback(new Error(t("v210831.please-enter-a-integer-fixed-2")));
      }
    } else {
      callback();
    }
  }
  multipleValueArr = (rule, value, callback) => {
    if (!value) {
      callback(new Error(t("v210831.please-select-a-limit")));
    } else {
      this.multipleValueVerity(value, callback);
    }
  };
  revisitCountTypeVerify(value, callback) {
    if (value === "1" && !this.returnForm.ritioNoLimit) {
      callback(new Error(t("v210831.please-enter-the-limit-ratio")));
    } else if (value === "2" && !this.returnForm.numberNoLimit) {
      callback(new Error(t("v210831.please-enter-the-limit-number")));
    } else if (this.returnForm.ritioNoLimit) {
      // 比例
      if (this.returnForm.ritioNoLimit > 0 && this.returnForm.ritioNoLimit <= 100.0) {
        this.returnForm.ritioNoLimit = Number(this.returnForm.ritioNoLimit).toFixed(2);
        callback();
      } else {
        callback(new Error(t("v210831.please-enter-legal")));
      }
    } else if (this.returnForm.numberNoLimit) {
      // 只能输入正整数
      if (this.returnForm.numberNoLimit > 0 && parseInt(this.returnForm.numberNoLimit)) {
        this.returnForm.numberNoLimit = parseInt(this.returnForm.numberNoLimit);
        callback();
      } else {
        callback(new Error(t("v210831.please-enter-a-integer")));
      }
    }
  }
  numberValueArr = (rule, value, callback) => {
    if (!value) {
      callback(new Error(t("v210831.please-select-a-limit")));
    } else {
      this.revisitCountTypeVerify(value, callback);
    }
  };
  handleSwitchAutoType(val) {
    console.log(val);
    this.returnForm.notifyMethods = [];
  }
  channel(value, callback) {
    if (this.returnForm.revisitType === "1") {
      callback();
    } else if (this.returnForm.notifyMethods.length) {
      callback();
    } else if (this.returnForm.pushWx || this.returnForm.pushSms) {
      callback();
    } else {
      callback(new Error(t("v210831.please-select-a-ditch")));
    }
  }
  pushWxValueArr = (rule, value, callback) => {
    console.log("[ value ]", value);
    this.channel(value, callback);
  };
  returnFormRule = {
    moneyNoLimit: [{ validator: this.multipleValueArr, trigger: "blur" }],
    revisitCountType: [{ validator: this.numberValueArr, trigger: "blur" }],
    revisitType: [{ required: true, message: t("v210831.please-select-a-type"), trigger: "blur" }],
    pushWx: [{ validator: this.pushWxValueArr, trigger: "blur" }],
    pushSms: [{ validator: this.pushWxValueArr, trigger: "blur" }],
    notifyMethods: [{ required: true, validator: this.pushWxValueArr, trigger: "blur" }],
    status: [{ required: true, message: t("setting.select-whether-enable"), trigger: "blur" }]
  };
  created() {
    this.init();
  }
  mounted() {}
  init() {
    this.getRevisitRule().then(res => {
      if (res.data) {
        // 金额限制
        if (res.data.amountLimit === "-1") {
          this.returnForm.amountLimit = undefined;
          this.returnForm.moneyNoLimit = "1";
        } else {
          this.returnForm.moneyNoLimit = "2";
          this.returnForm.amountLimit = res.data.amountLimit;
        }
        // 比例
        if (res.data.revisitCountType === 1) {
          this.returnForm.revisitCountType = "1";
          this.returnForm.ritioNoLimit = res.data.revisitCountValue;
        } else {
          this.returnForm.revisitCountType = "2";
          this.returnForm.numberNoLimit = Number(res.data.revisitCountValue);
        }
        // 回访方式
        this.returnForm.revisitType = res.data.revisitType + "";
        this.returnForm.status = res.data.status + "";
        this.returnForm.pushWx = !!res.data.pushWx;
        this.returnForm.pushSms = !!res.data.pushSms;
        this.returnForm.automaticPushStrategy = res.data.automaticPushStrategy;
        this.selectchannel = res.data.notifyMethods;
        this.returnForm.notifyMethods = res.data.notifyMethods;
      }
    });
  }
  changeRevisitType() {
    this.returnForm.notifyMethods = [];
  }
  iAmSure(returnForm) {
    let refForm = this.$refs[returnForm] as any;
    refForm.validate((valid, notPass) => {
      if (valid) {
        const params = {
          ...this.returnForm,
          amountLimit: this.returnForm.amountLimit || -1,
          pushSms: +this.returnForm.pushSms,
          pushWx: +this.returnForm.pushWx,
          // notifyMethods: [this.returnForm.pushWx ? "weixin" : null, this.returnForm.pushSms ? "sms" : null],
          revisitCountValue: this.returnForm.ritioNoLimit || this.returnForm.numberNoLimit
        };
        this.saveRevisitRule(params).then(res => {
          this.$message.success(t("v210831.return-visit-rule-save"));
        });
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
}
